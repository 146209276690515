"use client";

import React from "react";
import { useRouter, useSearchParams } from "next/navigation";

import { CompanyCreationDialog } from "@/components/company-switcher/company-creation-dialog";
import { CompanyCommandList } from "@/components/company-switcher/company-command-list";
import { type Groups } from "@/components/company-switcher/types";

export const CompanySelection = ({
  companyGroups,
}: {
  companyGroups: Groups;
}) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const [open, setOpen] = React.useState(false);
  const onCompanySelect = React.useCallback(
    async (companySlug: string) => {
      const next = searchParams.get("next");
      if (next) {
        // Set the company and refresh the page
        await fetch(`/api/auth/refresh?companySlug=${companySlug}`);
        return router.refresh();
      }
      return router.push(`/${companySlug}/dashboard/overview`);
    },
    [searchParams, router],
  );
  return (
    <div className="m-auto h-full">
      <CompanyCommandList
        groups={companyGroups}
        onCompanySelect={onCompanySelect}
        onNewCompanyClick={() => {
          setOpen(true);
        }}
      />
      <CompanyCreationDialog
        open={open}
        onOpenChange={setOpen}
        onCompanyCreate={() => {
          router.refresh();
        }}
      />
    </div>
  );
};
